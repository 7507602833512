export const fields = [
  {
    label: '№',
    key: 'id',
  },
  {
    label: 'Взыскатель',
    key: 'COMPANY_NAME',
    thClass: 'tableHeadClass',
    tdClass: 'tableHeadClass',
    editable: true,
  },
  {
    label: 'Общая сумма',
    key: 'total_sum',
    thClass: 'tableHeadClass',
    tdClass: 'tableHeadClass',
    editable: true,
  },
  {
    label: 'Исполнители',
    key: 'users',
    thClass: 'tableHeadClass',
    tdClass: 'tableHeadClass',
    editable: true,
  },
  {
    label: 'ДЕЙСТВИЯ',
    key: 'actions',
    thClass: 'tableHeadClass',
    tdClass: 'tableHeadClass',
  },
]

export const fields2 = [
  {
    key: 'id',
    label: '№',
    sortable: false,
  },
  {
    key: 'pay_his',
    label: 'Последние платежи',
    sortable: false,
  },
  {
    key: 'LAST_ACTION_DATE',
    label: 'ДАТА ПОСЛЕДНЕГО ДЕЙСТВИЯ',
    sortable: false,
  },
  {
    key: 'debtors',
    label: 'ФИО ДОЛЖНИКА',
    sortable: false,
  },
  {
    key: 'SUM',
    label: 'Сумма долга',
    sortable: false,
  },
  // {
  //   key: 'BASIS',
  //   label: 'Стадия',
  //   sortable: false,
  // },
  {
    key: 'DEBT_STATUS',
    label: 'Статус',
    sortable: false,
  },
]
